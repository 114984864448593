import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Push from "pushover-notifications";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import { Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { useSnackbar } from "notistack";
const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container" id="contact">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">Want to get in touch?</h3>
          </div>
          <div className="cta-action">
            <Input
              id="newsletter"
              type="email"
              label="Subscribe"
              labelHidden
              hasIcon="right"
              placeholder="Your email / phone"
            ></Input>
            <Button
              variant="contained"
              startIcon={<LoginIcon />}
              className="send"
              onClick={(e) => {
                if (document.getElementById("newsletter").value.trim() === "") {
                  enqueueSnackbar("Fill in Your contact detail first", {
                    autoHideDuration: 4000,
                    variant: "error",
                  });
                  return;
                }
                var p = new Push({
                  user: "uii1k5i3daxvsvswrvggbq9e28kd94",
                  token: "aqdhc6wjtjk1fi1mx4dr4zjfzdqt5f",
                });

                var msg = {
                  message: document.getElementById("newsletter").value, // required
                  title: "AEX contact request",
                  sound: "magic",
                  device: "devicename",
                  priority: 1,
                };

                p.send(msg, function (err, result) {
                  if (err) {
                    enqueueSnackbar(
                      "Message failed to be delivered, are you online?",
                      {
                        autoHideDuration: 4000,
                        variant: "error",
                      }
                    );
                  } else {
                    document.getElementById("newsletter").value = "";
                    enqueueSnackbar(
                      "Contact details sent, we will be in touch.",
                      {
                        autoHideDuration: 4000,
                        variant: "success",
                      }
                    );
                  }
                });
              }}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
